<template>
  <div :style="bg" class="container">
    <div class="loginbox">
      <div class="headbar">
        <div v-if="type == 'reg'">微信扫码进行注册</div>

        <div v-if="type == 'wechat'">
          请先完善账户信息
          <span style="font-size: 13px;margin-left:20px">绑定账户、设置昵称和关注十万八公众号</span>
        </div>
        <el-button type="primary" @click="login" size="mini">返回登录页面</el-button>
      </div>
      <div class="logincontent">
        <div class="qrimgwrap">
          <vue-qr
            ref="Qrcode"
            text="https://server.solar960.com/qrtowechat/user/"
            :callback="test"
            :size="3000"
            :logoSrc="logoSrc"
          ></vue-qr>
        </div>
      </div>
      <div class="footbar">
        <div v-if="type == 'reg'">
          <span style="margin: 0 10px;">1：扫码后进入个人中心页面，点击商家入口;</span> 2：在商家登录页面，点击商家入驻
        </div>
        <div v-if="type == 'wechat'">扫码后进入个人中心页面，并登录个人账户后进行设置昵称和关注公众号</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  components: { VueQr },
  data() {
    return {
      type: JSON.parse(this.$route.query.type),
      //根据type不同，类型区分是更新手机号还是新注册,向后台发送请求二维码的信息。
      //res.data,
      logoSrc: require("../assets/logo.png"),
      //扫码后返回的信息
      bg: {
        backgroundImage: "url(" + require("@/assets/logoback.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      }
    };
  },
  mounted() {
    localStorage.setItem("Authorization", "");
  },
  methods: {
    login() {
      this.$router.push({
        path: "/login"
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  height: calc(100vh - 80px);
  padding-top: 80px;
}
.loginbox {
  width: 60%;
  margin: 0px auto 0 auto;
  box-shadow: 0 0 10px #555;
  min-height: 500px;
  background: #fff;
  position: relative;
}
.headbar {
  font-size: 16px;
  padding: 20px 40px;
  background: #555;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.logincontent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 340px;
}
.slotstyle {
  width: 70px;
  text-align: center;
}
.footbar {
  background: #555;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;

}
.qrimgwrap {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  border: 1px solid #eee;
}
.qrimgwrap img {
  width: 160px;
  height: 160px;
}
</style>